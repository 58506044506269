// import de l'image de couverture
import coverImage from '../images/cov.png';

export default function Hero() {
    return (
        <section>
            {/* overlay */}
            <div className="relative max-h-[700px] py-6">
                {/* contenu principal */}
                <div className="flex flex-col absolute w-full my-4 md:my-12 lg:my-24">
                    <h1 className='text-center text-4xl md:text-5xl lg:text-6xl font-poppins mb-2 md:mb-3 font-black text-for'>Trouvez un véhicule</h1>
                    <h6 className='text-center font-poppins font-light text-for md:text-2xl'>Louez une voiture facilement <br /> proche de chez vous </h6>
                </div>
                {/* l'image de couverture */}
                <img src={coverImage} alt="" className="max-w-full mx-auto h-full print:hidden"/>
                
            </div>

        </section>
    )
}
