// import deLink depuis react router dom
import { Link } from 'react-router-dom';

export default function Car({car}) {
    return (
        <Link to={`/car/${car.id}`} title="Voir les détails" className='print:hidden'>
            {/* ma carte */}
            <div  className="mx-auto rounded-xl hover:shadow-lg transition duration-300 group w-[270px] lg:w-[350px]">
                
                {/* image de la carte */}
                <div className="bg-three py-4 rounded-xl rounded-b-none relative">
                    <img src={car.image} alt={car.name} className="cursor-pointer md:group-hover:scale-105 duration-300"  />
                    <span className="bg-one px-2 py-1 text-white rounded-xl cursor-pointer absolute right-2">
                        {car.price} XAF
                    </span>
                </div>
    
                {/* contenu de la carte */}
                <div className="flex justify-between items-center px-4 pt-4 mt-4">
                        <h4 className="font-bold font-poppins uppercase mb-1">{car.name}</h4>
                        {/* bloc de la catégorie du véhicule */}
                        <p className="texte uppercase"> {car.category} </p>
                </div>
                <hr className='w-[95%] mx-auto my-2'/>
                {/* bloc du bouton voir plus */}
                <div className='flex items-center justify-center'>
                    <Link to={`/car/${car.id}`} className="px-4 py-2 mb-3 bg-three rounded-full text-for">
                        Cliquez ici
                    </Link>
                </div>
                {/* fin du contenu de la carte */}
            </div>
        </Link>
            
    )
}
