import BackTx from "../components/BackTx";
import HeroAbout from "../components/HeroAbout";
import ServicesAbout from "../components/ServicesAbout";
import PacAuto from "../components/PacAuto";


export default function About() {
    return (
        <main>
            <HeroAbout />
            <ServicesAbout />
            <BackTx />
            <PacAuto />
        </main>
    )
}
