// import du composant contact
import ContactUs from "../components/ContactUs";
import HeroContact from "../components/HeroContact";
import Map from "../components/Map";


export default function Contact() {
    return (
        <main>
            {/* image de couverture + texte introductif */}
            <HeroContact />
            {/* information, numéros de téléphone + e-mail */}
            <ContactUs />
            {/* la géolocalisation du group-ged (goolge map) */}
            <Map />
        </main>
    )
}
