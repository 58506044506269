// import du composant détail du VL
import DetailsCar from '../components/DetailsCar';

export default function CarDetails() {
    return (
        <main>
                <DetailsCar />
        </main>
    )
}
