export const cars = [
    {
        id: 1,
        name: 'suzuki alto 800',
        category: 'citadine',
        price: '30.000',
        image: require('./images/alto.png'),
        statut: 'disponible',
        caution: '200.000',
        star: 3

        
    },
    {
        id: 2,
        name: 'chevrolet spark lt',
        category: 'citadine',
        price: '33.000',
        image: require('./images/sparkLT.png'),
        statut: 'indisponible',
        caution: '200.000',
        star: 3
        
    },
    {
        id: 3,
        name: 'suzuki ciaz',
        category: 'berline',
        price: '45.000',
        image: require('./images/S-ciaz.png'),
        statut: 'disponible',
        caution: '250.000',
        star: 4
        
    },
    {
        id: 4,
        name: 'toyota rush',
        category: 'suv',
        price: '60.000',
        image: require('./images/T-rush.png'),
        statut: 'disponible',
        caution: '500.000',
        star: 4
        
    },
    {
        id: 5,
        name: 'toyota rav4',
        category: 'suv',
        price: '60.000',
        image: require('./images/T-rav4.png'),
        statut: 'disponible',
        caution: '500.000',
        star: 4
        
    },
    {
        id: 6,
        name: 'toyota prado tx',
        category: '4X4',
        price: '85.000',
        image: require('./images/T-tx.png'),
        statut: 'disponible',
        caution: '500.000',
        star: 4
        
    },
];


export const best = [
    {
        id: 1,
        name: 'suzuki alto 800',
        category: 'citadine',
        price: '30.000',
        image: require('./images/alto.png'),
        
    },
    {
        id: 2,
        name: 'chevrolet spark lt',
        category: 'citadine',
        price: '33.000',
        image: require('./images/sparkLT.png')
        
    }
];


export const clients = [
    {
        id: 1,
        name: "E3A",
        image: require('./images/e3a.jpg'), 
    },
    {
        id: 2,
        name: "CFAO Motors",
        image: require('./images/cfao.jpg'),
        
    },
    {
        id: 3,
        name: "Gesparc",
        image: require('./images/gesparc.jpg'),
        
    },
];