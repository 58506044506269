// import des routes pour la navigation
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

// import des pages
import Home from "./pages/Home";
import About from "./pages/About";
import CarDetails from "./pages/CarDetails";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function App() {

  // box affichage(render)
  return (
    <Router>
      {/* l'entête du site */}
        <Header />
        {/* le corps du site */}
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/a-propos' element={<About/>} />
            <Route path='/car/:id' element={<CarDetails/>} />
            <Route path='/contact' element={<Contact/>} />
        </Routes>
        {/* le pied de page */}
        <Footer />
    </Router>
  )
}

