// import logo & cachet
import Logo from '../images/ged-services.png';
// import icon
import { MdOutlineClose } from 'react-icons/md';
import { GiCheckMark } from 'react-icons/gi';

export default function Modal({ visible, handleClose }) {

    // comportement
    if(!visible) return null;
    // affichage (render)
    return (
        <section className="z-10 bg-black bg-opacity-30 backdrop-blur-sm fixed inset-0 flex justify-center items-center" onClick={handleClose}>
            <div className="container mx-auto bg-white rounded-xl px-4 md:px-0 py-10">
                <div className="mx-auto px-4 flex justify-between items-center mb-3">
                    <img src={Logo} alt="" className='w-18 md:w-24'/>
                    <button onClick={handleClose}>
                        <MdOutlineClose size={25} />
                    </button>
                </div>

                {/* le titre */}
                <h4 className='mb-1 uppercase text-md md:text-lg text-center'>conditions générales de location</h4>
                {/* block du contenu des conditions */}
                <div className='mx-auto px-4'>
                    <h6 className='mb-2'>Responsabilité pour dommage : </h6>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Le locataire est responsable pour tous dommages causés au véhicule à l'intérieur, extérieur 
                        et à autrui durant la durée de la location. Toute pièce manquante sera facturée. Le locataire est tenu de restituer le véhicule
                        en état de propreté.
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Le locataire s'engage à restituer le véhicule dans l'état initial, à l'
                        heure indiquée dans le présent contrat, et à l'adresse où il lui avait remis, une marge de 1 heure pourrait cependant être autorisée,
                        passé ce délais le locataire se verra être facturé 1/2 tarif et passé 5h il serait facturé plein tarif.
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Les véhicules doivent dans la mesure du possible rester sur des routes carrossables. 
                        Il est strictement interdit de sortir de la <span className='font-bold ml-1 bg-yellow-500'> zone urbaine du lieu de livraison du véhicule</span>.
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Il est entendu que le véhicule reste à la Responsabilité du client jusqu'à la 
                        prise en charge par le service de dépannage. Le client n'est toutefois pas autorisé à intervenir (directement ou indirectement en cas d'anomalie).
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Ce contrat fait également office de reconnaissance de dette. Si une erreur de carburant est constatée,
                        les frais de réparation seront à la charge du locataire, le carburant sera facturé 10.000F CFA par 1/4 de plein manquant.
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Le locataire reconnait avoir pris connaissance des conditions générales du présent
                        contrat et s'engage à respecter les présentes conditions.
                    </p>
                    <p className='text-xs flex mb-2 bg-yellow-500'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> NB : les livraisons et receptions de véhicules sont gratuites aux heures ouvrables*, passé ces heures
                        une taxe sera appliquée à chaque service. Les locations de nuits ne sont pas acceptée (au delà de 19h00).
                    </p>
                    {/* conditions de locations */}
                    <h6 className='mb-2'>Conditions de location et pièces à fournir : </h6>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Âge du conducteur : 23 ans.
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Permis de conduire : B.
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Dépôt garentie : chèque ou espèce selon le type de véhicule choisit.
                    </p>
                    <p className='text-xs flex mb-2'>
                        <GiCheckMark size={16} className='hidden md:flex mr-1'/> Carte nationale d'itentité ou passeport.
                    </p>
                    <hr className='opacity-10 my-2'/>
                    {/* autres */}
                    <p className='text-xs italic'>
                        (*) Heures ouvrables: du lundi au vendredi 7h30-16h30 / samedi de 9h00 à 12h00, <br />
                        <span className='font-bold'>Tarif de livraison: 3.000fcfa Tarif de reception: 3.000fcfa </span> (Applicable hors heures ouvrables).
                    </p>
                </div>
            </div>
        </section>
    )
}
