// import link depuis react router dom
import { Link } from "react-router-dom";

export default function CardBest({item}) {
    // états, données(state)

console.log('item');

    // affichage(render)
    return (
        <Link to={`/car/${item.id}`} className="print:hidden" title="Voir les détails">
        {/* /ma carte */}
            <div  className="mx-auto rounded-xl hover:shadow-lg md:hover:shadow-2xl transition duration-300 group w-[400px]
            border">
                
                {/* image de la carte */}
                <div className="bg-three py-4 rounded-xl rounded-b-none">
                    <img src={item.image} alt={item.name} className="cursor-pointer md:group-hover:scale-105 duration-300"  />
                </div>

                {/* contenu de la carte */}
                <div className="flex justify-between items-center px-4 py-6 mt-3">
                    <div>
                        <h4 className="font-bold font-poppins uppercase mb-1">{item.name}</h4>
                        {/* bloc de la catégorie du véhicule */}
                        <p className="texte uppercase"> {item.category} </p>
                    </div>
                    <span className="bg-one px-2 py-1 text-white rounded-xl cursor-pointer"> {item.price} XAF</span>
                </div>
            </div>
        </Link>
    )
}
