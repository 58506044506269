// import image de couverture
import CoverImage from "../images/ciaz.png";

export default function HeroAbout() {
    return (
        <section>
            {/* overlay */}
            <div className="relative max-h-[573px] py-6 container mx-auto">
                {/* contenu principal */}
                <div className="flex flex-col absolute w-full px-4 md:px-0">
                    <h1 className='text-4xl md:text-5xl lg:text-6xl font-poppins mb-1 font-black text-for'>
                        La location pas <br /> chère
                    </h1>
                    <h6 className='font-poppins font-light text-for md:text-2xl w-[300px] md:w-[450px] bg-white/50 rounded-xl md:bg-transparent'>
                        Les prix les plus bas <br /> de la location de véhicules 
                    </h6>
                </div>
                {/* l'image de couverture */}
                <img src={CoverImage} alt="" className="max-w-full mx-auto h-full object-cover print:hidden"/>
            </div>
        </section>
    )
}
