// importation des données clients
import { clients } from "../datas";

// import du composant client
import CardClient from "./CardClient";

export default function Clients() {
    // affichage du composant
    return (
        <section className="py-10 bg-three">
            <div className="container mx-auto">
                    {/* bloc du titre */}
                    <div className='flex items-center justify-center'>
                        <div className='w-9 h-1 bg-one mr-0.5'></div>
                        <h2 className='text-2xl md:text-3xl lg:text-4xl uppercase mb-3 text-center md:text-left'>nous travaillons ensemble</h2>
                    </div>
                    {/* bloc qui affiche les logos des clients et des partenaires */}
                    <div className="grid md:grid-cols-3 gap-3 md:gap-1 py-9">
                        {/* boucle sur le tableau des client */}
                        {clients.map((client, index) => {
                            return (
                                <CardClient key={index} client={client}/>
                                
                            )
                        })}
                    </div>
            </div>
        
        </section>
    )
}
