

export default function ServicesAbout() {
    return (
        <section className="bg-one">
            <div className="container mx-auto py-10 text-three">
                {/* bloc du titre */}
                <div className='flex items-center justify-center mb-6'>
                    <div className='w-9 h-1 bg-three mr-0.5'></div>
                    <h2 className='text-2xl md:text-3xl lg:text-4xl uppercase mb-3 text-center'>
                        nos services
                    </h2>
                </div>
                {/* bloc des services */}
                <div className="grid md:grid-cols-2 px-4 md:px-0 gap-4 md:gap-6">
                        {/* location véhicule */}
                        <div className="">
                            <h3 className='mb-2 uppercase text-md md:text-lg text-center'> Location véhicules </h3>
                            <div className="px-4 md:px-0">
                                <p className="texte-two">
                                Vous avez besoin d’un véhicule pour un déplacement domestique et professionnel, pour
                                partir en vacances quelques jours? <br />
                                Optez pour une location de voiture pratique et accessible à tous.
                                </p>

                                <p className="texte-two">
                                    Nous mettons à votre disposition des solutions vous permettant de profiter d’un véhicle
                                    adapté à vos besoins, à un prix défiant toutes concurences.
                                </p>
                            </div>
                        </div>
                        {/* prestations logistiques */}
                        <div>
                            <h3 className='mb-2 uppercase text-md md:text-lg text-center'>prestations logistiques</h3>
                            <div className="px-4 md:px-0">
                                <p className="texte-two">
                                    Vous avez un besoin de logistique dans le cadre professionnel ou d’une salle
                                    de travail aménagée pour vos réunions?
                                </p>
                                <p className="texte-two">
                                    Choississez un service fiable, flexible et efficace pour vous assurer une 
                                    prestation de qualité.
                                </p>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    )
}
