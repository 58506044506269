// les hooks
import {useState} from 'react';
// import des liens
import {Link} from 'react-router-dom';
// import du logo
import Logo from '../images/ged-services.png';
// import icons
import { VscMenu, VscClose, VscMail, VscHome } from "react-icons/vsc";
import { GoLightBulb } from "react-icons/go";

export default function Header() {
    // état, données(state)
    const [nav, setNav] = useState(false);

    // comportements(events)

    // affichage(render)
    return (
        <header className='font-poppins sticky top-0 left-0 z-10 bg-white bg-opacity-40 shadow-md shadow-one/10 backdrop-blur-sm'>
            {/* la navigation sur desktop */}
            <nav className='container mx-auto flex justify-between items-center py-1 px-4 md:px-0'>
                <div>
                    <Link to="/">
                        <img className='w-24 md:w-32' src={Logo} alt="Le logo de GED Services" />
                    </Link>
                </div>
                {/* les liens du menu de navigation */}
                <ul className='hidden md:flex items-center space-x-3 uppercase font-light'>
                    <Link to="/" className='hover:bg-one hover:text-three transition duration-200 p-2'>Accueil</Link>
                    <Link to="/a-propos" className='hover:bg-one hover:text-three transition duration-200 p-2'>A propos</Link>
                    <Link to="/contact" className='hover:bg-one hover:text-three transition duration-200 p-2'>Contact</Link>
                </ul>
                {/* bouton qui ouvre la navigation sur mobile */}
                <button className='flex md:hidden' onClick={() => setNav(!nav)}>
                    <VscMenu size={25} />
                </button>
            </nav>

            {/* menu mobile */}
            <div className={nav ? 'flex md:hidden w-[480px] bg-one/95 h-screen fixed left-0 top-0 z-10 px-9 duration-300 text-white' : "fixed -left-[100%] top-0 z-10 px-9 duration-300 text-white"}>
            
                {/* boutton de fermeture du menu sur mobile */}
                <VscClose size={25} className="right-4 top-7 absolute cursor-pointer" onClick={() => setNav(!nav)}/>

                {/* les liens du menu sur mobile */}
                <ul className='flex flex-col  uppercase font-light mt-[70px] space-y-2'>
                    <Link to="/" className="flex flex-row items-center" onClick={() => setNav(!nav)}>
                        <VscHome size={20} className="mr-2" /> Accueil
                    </Link>

                    <Link to="/a-propos" className="flex flex-row items-center" onClick={() => setNav(!nav)}>
                        <GoLightBulb size={20} className="mr-2" />A propos
                    </Link>

                    <Link to="/contact" className="flex flex-row items-center" onClick={() => setNav(!nav)}>
                        <VscMail size={20} className="mr-2" />Contact
                    </Link>
                </ul>
            </div>
        </header>
    )
}
