// import du hook usestate
import { useState } from 'react';
// import des link de react-router-dom
import { Link } from 'react-router-dom';

// importation de l'image de fond & du logo du group blanc
import FooterImage from '../images/footerimage.jpg';
import Logo from '../images/group-ged.png';

// import icon
import {IoLogoWhatsapp} from 'react-icons/io';
import Modal from './Modal';

export default function Footer() {
    // états, données
    const [showModal, setShowModal] = useState(false);

    // comportement
    const handleClose = () => setShowModal(false);

    // affichage, rendu visuel
    return (
        <footer className="font-poppins max-w-full relative print:hidden">
            <div className='absolute bg-black/80 h-full w-full'>
                <div className="container mx-auto px-4 md:px-0 py-8 md:py-4 text-three">
                    {/* groupe-1 */}
                    <div className="flex-col md:flex">
                        {/* bloc du logo du Grou-GED */}
                        <div className='max-w-[7%]'>
                            <a href="https://group-ged.com" rel="noopener noreferrer" target="_blank">
                                <img src={Logo} alt="Le logo du Group-GED"  className='px-4 md:px-0 w-24 md:w-20'/>
                            </a>
                        </div>
                        {/* bloc liens & contact */}
                        <div className='grid grid-cols-2 justify-items-stretch gap-3 md:gap-2'>
                            {/* les liens utilent */}
                            <div className='mx-auto'>
                                <h6 className='mb-1 uppercase text-md md:text-lg'>liens utiles</h6>
                                <div className='flex flex-col'>
                                    <Link to="/" className='font-light text-sm transition duration-300 hover:underline underline-offset-4'>Accueil</Link>
                                    <Link to="/a-propos" className='font-light text-sm transition duration-300 hover:underline underline-offset-4'>A propos</Link>
                                    <Link to="/Contact" className='font-light text-sm transition duration-300 hover:underline underline-offset-4'>Contact</Link>
                                    <p onClick={() => setShowModal(true)} title="Voir les conditions location chez GED Services"
                                    className='font-light text-sm transition duration-300 hover:underline underline-offset-4 cursor-pointer'>
                                        Conditions de location
                                    </p>
                                </div>
                            </div>
                            {/* les contacts */}
                            <div className='mx-auto'>
                                <h6 className='mb-1 uppercase text-md md:text-lg'>nos contacts</h6>
                                {/* portable */}
                                <p className='font-light text-xs md:text-sm'>
                                    <span className='uppercase'>tél : </span> (+241) 62 942 288
                                </p>
                                {/* fixe */}
                                <p className='font-light text-xs md:text-sm'>
                                    <span className='uppercase'>tél-fixe : </span> (+241) 65 557 495
                                </p>
                                {/* e-mail */}
                                <p className='font-light text-xs md:text-sm'>
                                    <span className='uppercase'>e-mail : </span> infos.loca@group-ged.com
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* la newsletter et le chat whatsapp */}
                    <div className="grid md:grid-cols-2 gap-2 mt-2">
                        {/* la newletter */}
                        <div className='mx-auto'>
                            <h6 className='mb-1 uppercase text-md md:text-lg'>rejoindre notre newsletter</h6>
                            <div className='rounded-full w-1/2 flex items-center justify-between'>
                                <input type="text"  placeholder='Saisissez votre email' 
                                className='font-light bg-three rounded-l-full text-xs focus:outline-none py-2 text-for'/>
                                <button className='bg-for rounded-l-none uppercase'>s'abonner</button>
                            </div>
                        </div>
                        {/* whatsapp */}
                        <div className='flex items-center mt-2 mx-auto'>
                            <span className='px-3 py-1'>demandez un devis </span> <button>
                                <a href="https://api.whatsapp.com/send?phone=62942288" target="_blank" rel="noopener noreferrer">
                                    <IoLogoWhatsapp size={20}  className="animate-bounce"/>
                                </a>
                            </button>
                        </div>
                    </div>
                    {/* la sépartion */}
                    <hr className='my-4 opacity-20'/>
                    {/* le copyright & l'équipe design */}
                    <div className="grid md:grid-cols-2 gap-1 mx-auto px-4 md:px-0">
                            <p className='texte'>&copy; tous droits réservés - GED Services 2022</p>
                            <p className='texte text-left md:text-right '>Design & Développement, le SI du Group-GED</p>
                    </div>
                </div>
            </div>
            {/* image de couverture du pied de page */}
            <img src={FooterImage} alt="" className='w-full mx-auto h-[470px] object-cover' />

            <Modal  visible={showModal} handleClose={handleClose} />
        </footer>
    )
}
