// import icons
import {MdOutlineLocalShipping} from "react-icons/md";
import {IoCarSportSharp} from "react-icons/io5";

export default function Services() {
    return (
            <section className='bg-one py-10 print:hidden'>
                <div className="container mx-auto px-4 md:px-0 grid md:grid-cols-2 gap-5 md:gap-3">
                    {/* service1 */}
                    <div className="mx-auto">
                        <IoCarSportSharp size={90}  className="text-three mx-auto"/>
                        <h3 className="font-poppins text-xl sm:2xl md:3xl lg:4xl text-three uppercase">location véhicules</h3>
                    </div>
                    {/* service2 */}
                    <div className="mx-auto">
                    <MdOutlineLocalShipping size={90}  className="text-three mx-auto"/>
                    <h3 className="font-poppins text-xl sm:2xl md:3xl lg:4xl text-three uppercase">Prestations logistiques</h3>
                    </div>
                </div>
            
            </section>
    )
}
