// import du module best depuis data
import { best } from "../datas.js";
// import du composant card
import CardBest from "./CardBest";

export default function BestLocation() {
    return (
        <section className="py-10 print:hidden">
            <div className="container mx-auto">
                    {/* bloc du titre */}
                    <div className='flex items-center justify-center'>
                        <div className='w-9 h-1 bg-one mr-0.5'></div>
                        <h2 className='text-2xl md:text-3xl lg:text-4xl uppercase mb-3 text-center md:text-left'>véhicules les plus loués</h2>
                    </div>
                    {/* bloc qui présente les voitures les plus louées */}
                    <div className="grid md:grid-cols-2 gap-9 md:gap-4 py-12">
                            {best.map((item, index) =>{
                                return (
                                    <CardBest key={index} item={item}/>
                                    
                                )
                            })}
                    </div>
            </div>
        </section>
    )
}
