// import icons
import {BsFillTelephoneFill} from 'react-icons/bs';
import {HiEnvelope} from 'react-icons/hi2';
import {SiGooglemaps} from 'react-icons/si';

export default function ContactUs() {
    return (
        <section>
            <div className="container mx-auto">
                <div className="grid md:grid-cols-2 gap-2">
                    {/* bloc du contenu */}
                    <div className="bg-one px-4 md:px-0">
                        <div className="bg-white bg-opacity-5 backdrop-blur-xl rounded-3xl shadow-2xl mx-auto max-w-lg my-[10%] p-4 text-three">
                            <p className="texte">
                                Pour vous apporter le meilleur soutien lors de vos locations, nous sommes disponible du lundi au samedi.
                            </p>
                            <p className="texte">
                                Afin que nous puissions vous aider dans les meilleurs délais et pour  faciliter la communication, 
                                utilisez le bouton whatsapp sur l'image de couverture.
                            </p>
                            <p className="texte">
                                Nous sommes disponibles à vous fournir un service de qualité, car nous attachons beaucoup d’importance à la 
                                confiance que vous nous témoignez.
                            </p>
                            <p className="texte">
                                Merci d’avoir choisi GED Services.
                            </p>
                        </div>
                    </div>

                    {/* toutes les coordonnées */}
                    <div className='my-auto px-4 md:px-0'>
                        {/* téléphone */}
                        <div className="flex md:flex-col items-center space-x-2 md:space-x-0">
                            <div className='bg-one text-three p-6 rounded-full' >
                                <BsFillTelephoneFill size={25} />
                            </div>
                            <div className='flex flex-col md:flex'>
                                <p className='mt-1'> (+241) 62 942 288 </p> 
                                <p className='mt-1'> (+241) 65 557 495 </p>
                            </div>
                        </div>
                        <hr className='my-1' />
                        {/* e-mail */}
                        <div className="flex md:flex-col items-center space-x-2 md:space-x-0">
                            <div className='bg-one text-three p-6 rounded-full' >
                                <HiEnvelope size={25} />
                            </div>
                            <p className='mt-1'> infos.loca@group-ged.com </p> 
                        </div>
                        <hr className='my-1' />
                        {/* e-mail */}
                        <div className="flex md:flex-col items-center space-x-2 md:space-x-0 mb-2 md:mb-0">
                            <div className='bg-one text-three p-6 rounded-full' >
                                <SiGooglemaps size={25} />
                            </div>
                            <p className='mt-1'> BP: 2562, Rue du Docteur BALLAY (face à l'ancien ELECTRA)</p> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
