import {Link} from 'react-router-dom';
// import de l'image
import Rush from '../images/rush.png';

export default function AboutHome() {
    return (
        <section className='py-10'>
            <div className="container mx-auto grid md:grid-cols-2 gap-4">
                {/* bloc image */}
                <div className='print:hidden'>
                    <img src={Rush} alt="" className="w-[530px] md:w-[700px] mx-auto"/>
                </div>
                {/* bloc titre & contenu */}
                <div className="my-auto text-for px-4 md:px-0">
                    {/* bloc du titre */}
                    <div className='flex items-center'>
                        <div className='w-9 h-1 bg-one mr-0.5'></div>
                        <h2 className='text-2xl md:text-3xl lg:text-4xl uppercase mb-3 text-center md:text-left'>à propos de nous</h2>
                    </div>
                    <p className='texte'>
                        GED Services est une agence de location de véhicules et de logistique positionnée pour
                        répondre de manière efficace au besoin du marché.
                    </p>
                    <p className='texte'>
                        La location de véhicules et la logistique sont au coeur de notre activité, nous sommes
                        engagés pour une prestation de qualité , efficace, fiable et sécurisée.
                    </p>
                    <p className='texte'>
                        En tant que plateforme, notre mission est d’apporter une bouffée d’air frais aux citadins 
                        en proposant un service de qualité et de proximité.
                    </p>
                    <p className='texte'>
                        Notre objectif est de fournir un excellent rapport qualité prix pour vous permettre de
                        trouver la location dont vous avez besoin à un tarif très compétitif.
                    </p>
                    {/* le bouton qui emmene sur la page à propos  */}
                    <button className='bg-one hover:bg-two transition-all duration-300 text-three'>
                        <Link to="/a-propos">en savoir plus</Link>
                    </button>
                </div>
            </div>
        </section>
    )
}
