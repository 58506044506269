// import des icons
import { BsFillTrophyFill } from 'react-icons/bs';
import { FaAward } from 'react-icons/fa';
import { TbCalendarOff } from 'react-icons/tb';

export default function BackTx() {
    return (
        <section className="bg-no-repeat bg-fixed bg-cover bg-hero w-full h-[605px] relative object-cover">
            <div className="bg-black/60 absolute top-0 left-0 h-full w-full py-4 md:py-6 lg:py-10">
                    <div className="container mx-auto text-three px-4 md:px-0">
                        {/* bloc du titre */}
                        <div className='flex items-center justify-center mb-6'>
                            <div className='w-9 h-1 bg-three mr-0.5'></div>
                            <h2 className='text-2xl md:text-3xl lg:text-4xl uppercase mb-3 text-center'>
                                Location de voitures pas chère
                            </h2>
                        </div>
                        <p className="texte text-center">
                            Chez GED Services, notre parc automobile est composé de véhicules de type citadine, berline, SUV, 4X4 et bien d’autres. <br />
                            Si vous cherchez une voiture de type familiale, nous la trouverons pour vous à un prix abordable. <br />
                            Réservez votre voiture maintenant et obtenez un prix adapté à votre budget.
                        </p>
                        {/* qualité, annulation &  */}
                        <div className="grid md:grid-cols-3 gap-3 mt-1 md:mt-8 lg:mt-20">
                            {/* expérience */}
                            <div className='mx-auto'>
                                <div className='flex flex-col items-center'>
                                    <FaAward size={40}/>
                                    <h3 className='mb-1 uppercase text-md md:text-lg my-1 md:my-2'> qualité et expérience</h3>
                                </div>
                                {/* contenu */}
                                <p className="text-sm md:text-lg font-light text-center">
                                    Depuis 2018, nous mettons à votre disposition
                                    des voitures à des tarifs flexibles.
                                </p>
                            </div>
                            {/* annulation */}
                            <div className='mx-auto'>
                                <div className='flex flex-col items-center'>
                                    <TbCalendarOff size={40}/>
                                    <h3 className='mb-1 uppercase text-md md:text-lg my-1 md:my-2'> annulation gratuite</h3>
                                </div>
                                {/* contenu */}
                                <p className="text-sm md:text-lg font-light text-center">
                                    Annulez sans frais jusqu’à 48h avant l’effectivité de la location.
                                </p>
                            </div>
                            {/* service de qualité */}
                            <div className='mx-auto'>
                                <div className='flex flex-col items-center'>
                                    <BsFillTrophyFill size={40}/>
                                    <h3 className='mb-1 uppercase text-md md:text-lg my-1 md:my-2'> excellent service</h3>
                                </div>
                                {/* contenu */}
                                <p className="text-sm md:text-lg font-light text-center">
                                    Notre service client suit l’ensemble des véhicules en 
                                    location pour les opérations tel que les maintenances.
                                </p>
                            </div>

                        </div>
                    </div>
            </div>
        </section>
    )
}
