// import données des véhicules
import { cars } from '../datas';
// import du composant car(voiture)
import Car from './Car';

export default function PacAuto() {
    return (
        <section className="py-10">
            <div className="container mx-auto px-4 md:px-0">
                {/* bloc du titre */}
                <div className='flex items-center justify-center mb-4 md:mb-10'>
                    <div className='w-9 h-1 bg-one mr-0.5'></div>
                    <h2 className='text-2xl md:text-3xl lg:text-4xl uppercase mb-3'>
                        nos offres de voitures
                    </h2>
                </div>

                <div className="grid sm:grid-cols-2 md:grid-cols-3 sm:gap-12 gap-6 mb-4 print:hidden">
                    {/* boucle sur chaque véhicule */}
                    {cars.map((car) => {
                        return (
                            <Car key={car.id} car={car} />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
