// import des composants de la page
import AboutHome from "../components/AboutHome";
import BestLocation from "../components/BestLocation";
import Clients from "../components/Clients";
import Hero from "../components/Hero";
import Location from "../components/Location";
import Services from "../components/Services";



export default function Home() {
    return (
        <main>
            <Hero />
            <Services />
            <AboutHome/>
            <Location />
            <BestLocation />
            <Clients />
        </main>
    )
}
