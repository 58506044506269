// import icon
import {IoLogoWhatsapp} from 'react-icons/io';

export default function HeroContact() {
    return (
        <section className="bg-no-repeat bg-cover bg-center bg-contactImg w-full h-[450px] relative object-cover">
            <div className="bg-black/60 absolute top-0 left-0 h-full w-full py-4 md:py-6 lg:py-14">
                    <div className="container mx-auto px-4 md:px-0">
                        <div className="flex text-three flex-col">
                        <h1 className='text-4xl md:text-5xl lg:text-6xl font-poppins mb-2 md:mb-3 font-black'>
                            Contactez - nous
                        </h1>
                        {/* petite description */}
                        <h6 className='font-poppins font-light md:text-2xl'>
                            Restons en contact, <br /> Et bénéficiez de nos offres.
                        </h6>
                        </div>
                        {/* chat sur whatsapp */}
                        <div className='mt-[10%] w-[200px] flex flex-col justify-between items-center ml-[-150px] hover:ml-[-20px] duration-300 absolute left-0 text-three'>
                            <a href="https://api.whatsapp.com/send?phone=62942288" className='flex justify-between items-center h-12 w-full bg-green-500 px-3 rounded-tr-full rounded-br-full'
                            rel="noopener noreferrer" target="_blank">
                                <span className='ml-3'>discutons sur </span> <IoLogoWhatsapp size={25} className='mr-1 animate-bounce'/> 
                            </a>
                        </div>
                    </div>
            </div>
        </section>
    )
}
