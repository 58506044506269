

export default function Location() {
    return (
        <section className='relative print:hidden bg-locationImg bg-fixed bg-cover bg-center bg-no-repeat object-cover h-[520px]'>
            <div className='absolute bg-black bg-opacity-30 h-full w-full'></div>
            
        </section>
    )
}
