// import useParams depuis react router dom
import {useParams, Link} from 'react-router-dom';
// import des données
import {cars} from '../datas';
// import icon
import {IoStar} from 'react-icons//io5';
import {BsArrowReturnLeft} from 'react-icons/bs';
import {IoLogoWhatsapp} from 'react-icons/io';

export default function DetailsCar() {
        // états, données (state)
    // obtetion des paramètres du véhicule reçu en url via son id
    const {id} = useParams();
    // obtention d'une voiture basée sur son id
    const car = cars.find((car) => {
        return car.id === parseInt(id);
    });
    // affichage (render)
    return (
        <section className="py-10 h-auto print:hidden">
            <div className="container mx-auto px-4 md:px-0">
            <div className='grid md:grid-cols-2 gap-3 my-auto divide-x-0 md:divide-x'>
                <div className='my-auto'>
                        <div className='w-[30px] p-2'>
                            {/* bouton de retour à la page a-propos */}
                            <Link to={'/a-propos'} title="Retour à propos">
                                <BsArrowReturnLeft  size={25} className="bg-one text-three rounded-full" />
                            </Link>
                        </div>
                        {/* bloc de l'image */}
                        <img src={car.image} alt={car.name}  className='w-[100wv] mx-auto object-cover object-center'/>
                    </div>
                    {/* le bloc du contenu */}
                    <div className='max-w-md my-auto text-for pl-2'>
                        <h3 className='text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-3 text-center md:text-left'>
                            {car.name}
                        </h3>
                        <p className='font-semibold uppercase mb-4 text-center md:text-left'> {car.category} </p>
                        <div className='flex flex-col md:flex-row justify-between items-center gap-2 md:gap-4 mb-2 md:mb-6'>
                            {/* le prix */}
                            <span className='text-xs rounded-lg bg-three py-1 px-2 cursor-pointer'> Location journalière : {car.price} XAF </span>
                            {/* la caution */}
                            <span className='text-xs rounded-lg bg-black text-white py-1 px-2 cursor-pointer'> Montant caution : {car.caution} XAF </span>
                        </div>
                        {/* nombre d'étoile & le statut du véhicule */}
                        <div className='flex justify-between items-center'>
                            <p className='texte uppercase'> {car.statut} </p>
                            <div className='flex space-x-1'>
                                <p className='font-black flex gap-x-1'> 
                                    Etat : {car.star}  
                                </p> 
                                <IoStar className='text-yellow-400' size={19}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* chat sur whatsapp */}
                <p className='text-white'>
                    <a href="https://api.whatsapp.com/send?phone=62942288" className='flex justify-end items-center gap-2 h-12 bg-green-500 px-3 rounded-tr-full rounded-br-full'
                    rel="noopener noreferrer" target="_blank">
                        <span>discutons sur </span> <IoLogoWhatsapp size={25} /> 
                    </a>
                </p>
                {/* petite mention  */}
                <div className='flex flex-col md:flex-row items-start md:items-center py-2'>  
                    <p className='texte'> 
                    <span className='font-bold underline underline-offset-4'> NB :  </span> 
                    <span className='ml-2'>
                    Le montant de la caution peut-être soit de l'espèce, soit un chèque.Ladite caution, vous sera retounée
                    à la restitution du véhicule.
                    </span>
                    </p>
                </div>
        </div>
    </section> 
    )
}
